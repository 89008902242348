import { AxiosRequestConfig } from "axios";

export const getProfileConfig = (authorization: string | undefined): AxiosRequestConfig => ({
  method: "get",
  url: "/me/profile",
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    Authorization: authorization && `Bearer ${authorization}`,
  },
});
