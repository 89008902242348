import { BusinessHours } from "@/components/BusinessHours/types";
import { FileProps } from "@/components/FilesUpload/types";
import { LinkInterface } from "@/components/Links/types";
import { FrameType } from "@/components/QRFrame/types";

import { QRStyleAPIPayload } from "@/services/ApiConversorService/types/types";

export enum QrStatus {
  "DRAFT" = "Draft",
  "PAUSED" = "Paused",
  "ACTIVE" = "Active",
  "ARCHIVED" = "Archived",
}

export interface QrCodeProps {
  id: string;
  title: string;
  status: string;
  type: string;
  url: string;
  reference: string;
  preview?: string;
  content: any;
  structure: object;
  totalScans: number;
  createdAt: string;
  updatedAt: string;
  style: QRStyleAPIPayload;
  ai?: QRAi;
}

//QRs TYPES

export type QRDataType = keyof QRDataTypeMap;

export type QRType =
  | "website"
  | "links"
  | "listoflinks"
  | "pdf"
  | "url"
  | "apps"
  | "business"
  | "image"
  | "video"
  | "wifi"
  | "youtube"
  | "vcard"
  | "social"
  | "event"
  | "coupon"
  | "feedback"
  | "mp3";

export type QRTypeName =
  | "Website"
  | "Links"
  | "PDF"
  | "URL"
  | "Apps"
  | "Business"
  | "Image"
  | "Video"
  | "Wifi"
  | "YouTube"
  | "vCard"
  | "Social Media"
  | "Event"
  | "Coupon"
  | "Feedback"
  | "MP3";

export type QRDataTypeMap = {
  website: QRWebsiteType;
  links: QRLinksType;
  listoflinks: QRLinksType;
  pdf: QRPDFType;
  url: QRUrlType;
  apps: QRAppsType;
  business: QRBusinessType;
  image: QRImageType;
  video: QRVideoType;
  wifi: QRWifiType;
  youtube: QRYoutubeType;
  vcard: QRVcardType;
  social: QRSocialType;
  event: QREventType;
  coupon: QRCouponType;
  feedback: QRFeedbackType;
  mp3: QRAudioType;
};

export type QRAudioType = {
  typeName: "MP3";
  qrName: string;
  themeColor: {
    backgroundColor: string;
    buttonColor: string;
  };
  logoUrl: string;
  mp3FileUrl: string;
  mp3FileName: string;
  size: string;
  allowDownload: boolean;
  mp3ButtonText: string;
  mp3ButtonUrl: string;
  mp3Title: string;
  mp3Description: string;
  socialNetworks: SocialNetwork[];
  socialLogos?: string[];
};

export type QRWebsiteType = {
  typeName: "Website";
  qrName: string;
  themeColor: {
    backgroundColor: string;
    buttonColor: string;
  };
  logoUrl: string;
  companyName: string;
  title: string;
  subtitle: string;
  buttonText: string;
  buttonUrl: string;
  socialNetworks: SocialNetwork[];
  socialLogos?: string[];
};

export type QRLinksType = {
  typeName: "Links";
  qrName: string;
  themeColor: {
    backgroundColor: string;
    buttonColor: string;
  };
  logoUrl: string;
  title: string;
  description: string;
  links: LinkInterface[];
  socialNetworks: SocialNetwork[];
  socialLogos?: string[];
};

export type QRPDFType = {
  typeName: "PDF";
  qrName: string;
  pdfFileUrl: string;
  pdfFileName: string;
  size: string;
  themeColor: {
    backgroundColor: string;
    buttonColor: string;
  };
  companyName: string;
  pdfName: string;
  pdfDescription: string;
  websiteUrl: string;
  logoUrl?: string;
};

export type QRUrlType = {
  typeName: "URL";
  qrName: string;
  qrUrlLink: string;
};

export type QRWifiType = {
  typeName: "Wifi";
  qrName: string;
  qrNetworkName: string;
  qrNetworkPassword: string;
  qrNetworkSecurityType: string;
  qrNetworkHidden: boolean;
};

export type QRAppsType = {
  typeName: "Apps";
  qrName: string;
  themeColor: {
    backgroundColor: string;
    buttonColor: string;
  };
  logoUrl: string;
  appsName: string;
  appsCompany: string;
  appsDescription: string;
  appsWebsite: string;
  appLinks: {
    id: string;
    name: string;
    value: string;
    mod: string;
  }[];
};

export type QRBusinessType = {
  typeName: "Business";
  qrName: string;
  themeColor: {
    backgroundColor: string;
    buttonColor: string;
  };
  logoUrl: string;
  companyName: string;
  title: string;
  subtitle: string;
  buttonText: string;
  buttonUrl: string;
  businessHours: BusinessHours;
  address: string;
  numeration: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
  facilities: Icon_Facility[];
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  contactWebsite: string;
  aboutTheCompany: string;
  socialNetworks: SocialNetwork[];
};

export type QRImageType = {
  typeName: "Image";
  qrName: string;
  themeColor: {
    backgroundColor: string;
    buttonColor: string;
  };
  imageUrls: FileProps[];
  albumTitle: string;
  albumDescription: string;
  albumWebsite: string;
  buttonText: string;
  buttonUrl: string;
};

export type QRVideoType = {
  typeName: "Video";
  qrName: string;
  themeColor: {
    backgroundColor: string;
    buttonColor: string;
  };
  companyName: string;
  title: string;
  description: string;
  buttonText: string;
  buttonUrl: string;
  videoUrls: FileProps[];
  socialNetworks: SocialNetwork[];
  logoUrl?: string;
};

export type QRFeedbackType = {
  typeName: "Feedback";
  qrName: string;
  logoUrl: string;
  themeColor: {
    backgroundColor: string;
    buttonColor: string;
  };
  title: string;
  subtitle: string;
  ratingBase: number;
  categories: QRFeedbackCategory[];
  form: QRFeedbackForm;
};

export type QRYoutubeType = {
  typeName: "YouTube";
  qrName: string;
  youtubeUrl: string;
  logoUrl?: string;
};

export type QRVcardType = {
  typeName: "vCard";
  qrName: string;
  themeColor: {
    backgroundColor: string;
    buttonColor: string;
  };
  logoUrl: string;
  fullName: string;
  email: string;
  mobilePhone: string;
  landlinePhone: string;
  fax: string;
  website: string;
  companyName: string;
  profession: string;
  summary: string;
  address: string;
  numeration: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
  socialNetworks: SocialNetwork[];
};

export type QRSocialType = {
  typeName: "Social Media";
  qrName: string;
  logoUrl: string;
  themeColor: {
    backgroundColor: string;
    buttonColor: string;
  };
  headline: string;
  description: string;
  links: QRSocialMedia[];
};

export type QREventType = {
  typeName: "Event";
  qrName: string;
  themeColor: {
    backgroundColor: string;
    buttonColor: string;
  };
  logoUrl: string;
  infoTitle: string;
  infoDescription: string;
  infoActionTitle: string;
  infoActionUrl: string;
  eventDateTime: EventDateTime;
  locationAddress: string;
  locationNumeration: string;
  locationPostalCode: string;
  locationCity: string;
  locationState: string;
  locationCountry: string;
  organizerName: string;
  organizerPhone: string;
  organizerEmail: string;
  organizerWebsite: string;
  organizerAbout: string;
  facilities: Icon_Facility[];
};

export type QRCouponType = {
  typeName: "Coupon";
  qrName: string;
  themeColor: {
    backgroundColor: string;
    buttonColor: string;
  };
  logoUrl: FileProps[];
  infoCompanyName: string;
  infoHeadline: string;
  infoDescription: string;
  infoSalesBadge: string;
  infoButtonText: string;

  couponName: string;
  couponTermsAndConditions: string;
  couponUrlText: string;
  couponUrlLink: string;
  couponDateTime: CouponDateTimeType;
  locationAddress: string;
  locationNumeration: string;
  locationPostalCode: string;
  locationCity: string;
  locationState: string;
  locationCountry: string;

  socialNetworks: SocialNetwork[];
  socialLogos?: string[];
};

export type CouponDateTimeType = {
  couponValidDate: string;
  couponTimezone: string;
};

// QR STYLE PROPS
export enum QrStyleType {
  STANDARD = "STANDARD",
  AI = "AI",
}

export interface ThemeColor {
  background: string;
  button: string;
}

export type QrStyleTypeString = keyof typeof QrStyleType;

export interface QRStyle {
  styleType: QrStyleTypeString;
  dotsStyle: string;
  dotsColor: string;
  backgroundColor: string;
  cornerStyle: string;
  cornerBorderColor: string;
  cornerBackgroundColor: string;
  logoUrl: string;
  frameStyle: FrameType;
  frameColor: string;
  frameBackgroundColor: string;
  frameTextColor: string;
  frameText: string;
  controlImageAI?: string;
}

// QR AI PROPS
export interface QRAi {
  prompt: string;
  imageUrl: string;
  qrImageUrl: string;
  status: QRCodeDataAIStatus;
  selectedImageUrl?: string;
  generatedImages?: string[];
  externalId: number;
  provider: {
    name: string;
    id: number;
    trackId: string;
    options: string[];
    status: string;
  };
  error: null;
  createdAt: string;
  updatedAt: string;
}

export enum QRCodeDataAIStatus {
  CREATED = "created",
  COMPLETE = "complete",
  SUCCESS = "success",
  FAILED = "failed",
  PENDING = "pending",
}

export interface SocialNetwork {
  id: string;
  name: string;
  value?: string;
  mod?: string;
}

export type VcardData = {
  fullName?: string;
  mobilePhone?: string;
  landlinePhone?: string;
  fax?: string;
  email?: string;
  address?: string;
  website?: string;
  companyName?: string;
  profession?: string;
  logoUrl?: string;
};

export interface QRSocialMedia {
  id: string;
  name: string;
  text?: {
    value?: string;
  };
  url: {
    value: string;
  };
}

//DATE TIME

export type EventDateTime = {
  format: EventTimeFormat;
  allDay: boolean;
  timezone: string;
  start: DateTime;
  end: DateTime;
  actionTitle: string;
};

export type DateTime = {
  date: string;
  time: string;
  localDate?: string;
};

export type EventTimeFormat = "24-HOURS" | "AM-PM";

//ICONS

export enum Icon_Facility {
  accommodation = "accommodation",
  bar = "bar",
  cafe = "cafe",
  parking = "parking",
  restaurant = "restaurant",
  restrooms = "restrooms",
  seating = "seating",
  taxi = "taxi",
  wifi = "wifi",
  childFriendly = "child-friendly",
  nearPublicTransport = "near-public-transport",
  wheelchairAccess = "wheelchair-access",
  petFriendly = "pet-friendly",
}

// DOWNLOAD
export enum QRDownloadFormat {
  "png" = "png",
  "jpeg" = "jpeg",
  "webp" = "webp",
  "svg" = "svg",
}

//Feedback review

export interface FeedbackReview {
  categories: QRFeedbackReviewCategory[];
  createdAt: string;
  id: string;
  name?: string;
  rateAverage: number;
  ratingBase: number;
  updatedAt: string;
  contactData: QRFeedbackReviewContactData | undefined;
}

export interface QRFeedbackCategory {
  id: string;
  name: string;
  deleted?: boolean;
  rate?: number;
  rating?: number;
}

export interface QRFeedbackReviewCategory {
  id: string;
  name?: string;
  comment?: string | undefined;
  deleted?: boolean;
  rating?: number | undefined;
  rate?: number | undefined;
}
export interface QRFeedbackReviewContactData {
  name?: string;
  email?: string;
  phone?: string;
}

export interface QRFeedbackForm {
  name: boolean;
  phone: boolean;
  email: boolean;
}

export interface QRFeedbackTotalRateStats {
  totalRatingAverage: number;
}

export interface QRFeedbackReviewsData {
  items: FeedbackReview[];
  total: number;
}
