import { PlanProps } from "./plan";
import { QrStatus } from "./qr";
import { SubscriptionStatus } from "./subscription";

export enum UserStatus {
  ANONYMOUS = "Anonymous",
  REGISTERED = "Registered",
  SUBSCRIBED = "Subscribed",
  UNSUBSCRIBED = "Unsubscribed",
  TRIAL = "Trial",
}

export type EmailProvider = "google" | "email";

export interface UserProps {
  accountId?: string;
  userId: string;
  firstName: string;
  lastName: string;
  phone: string;
  userStatus: UserStatus;
  qrStatus: QrStatus;
  provider?: EmailProvider;
  selectedSubscriptionId?: string;
  email: string;
  freeTrialStartsAt: string | null;
  freeTrialEndsAt: string | null;
  subscription: {
    id: string;
    status: SubscriptionStatus;
    subscribed: boolean;
    unsubscribedAt?: string;
    requestedUnsubscribedAt?: string;
    payAt?: string;
    plan: PlanProps;
  };
}
