import { init, track, setUserId, add, identify, Identify } from "@amplitude/analytics-browser";
import { webAttributionPlugin } from "@amplitude/plugin-web-attribution-browser";
import UAParser from "ua-parser-js";

import { getSiteInfo } from "@/sites";
import { isClient } from "@/utils/browser";

const { domainName } = getSiteInfo(process.env.NEXT_PUBLIC_DOMAIN);

export const initAmplitude = () => {
  const amplitudeKey = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || "";

  if (!amplitudeKey || !isClient()) return;

  add(webAttributionPlugin());
  init(amplitudeKey, undefined, {
    serverZone: "EU",
    defaultTracking: false,
  });

  amplitudeDeviceType();
};

export const amplitudeDeviceType = () => {
  const parser: UAParser = new UAParser();
  const userAgent: UAParser.IResult = parser.getResult();

  let deviceType = "Unknown";
  if (userAgent.device.type === "mobile") {
    deviceType = "Mobile";
  } else if (userAgent.device.type === "tablet") {
    deviceType = "Tablet";
  } else {
    deviceType = "Desktop";
  }

  const identifyEvent = new Identify();
  identifyEvent.set("User Device", deviceType);

  identify(identifyEvent);
};

export const amplitudeTrackEvent = (name: string, data?: Record<string, any>) => {
  if (process.env.NEXT_PUBLIC_ENV === "local") return;

  const formattedDomain = domainName.replace(".com", "");

  const eventData = { ...data, site: formattedDomain };
  track(name, eventData);
};

export const amplitudeTrackUser = (userId: string) => {
  if (process.env.NEXT_PUBLIC_ENV === "local") return;
  setUserId(userId);
};
