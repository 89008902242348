import { AxiosResponse } from "axios";

import client from "../client";
import { postLoginConfig } from "./postLoginConfig";
import { PostLoginPayload, PostLoginResponseData } from "./types";

const postLogin = async (
  data?: PostLoginPayload,
  reference?: string
): Promise<AxiosResponse<PostLoginResponseData>> => {
  return await client({ ...postLoginConfig, data: { reference, ...data } });
};

export default { postLogin };
