import { THEME } from "@/themes/types";
import { SiteInfo } from "../types";

import { NextFontWithVariable } from "next/dist/compiled/@next/font";
import { kebabCase, startCase } from "lodash";

const { indexedPaths } = require("../seo.ts");
const theme = process.env.NEXT_PUBLIC_THEME as THEME;

import { fonts } from "@/themes/kalimotxo/fonts/fonts";

export const onImportFonts = (fonts: { [x: string]: NextFontWithVariable }) => {
  return Object.keys(fonts).map((key) => {
    const kebab = kebabCase(key);
    const formattedFontName = startCase(kebab).split(" ").join("-");
    return formattedFontName as unknown as NextFontWithVariable;
  });
};

export const localhostQRCreator: SiteInfo = {
  domainName: "localhost",
  siteName: "QR Creator Local",
  isFreeTrial: false,
  siteTheme: theme,
  siteEmail: "info@qrcreatorlocal.com",
  siteWhiteLogo: "/svg/logo-white.svg",
  siteBlackLogo32: "/svg/logo32.svg",
  siteBlackLogo28: "/svg/logo28.svg",
  siteBlackLogoFooter: "/svg/logoFooter.svg",
  favicon: "/images/favicon-default.png",
  indexedPaths: indexedPaths["onlineqrcode.com"],
  companies: ["ebdaniTradeSL"],
  fonts: onImportFonts(fonts),
};
