import { SiteInfo } from "../types";
import { onImportFonts } from "./localhost";

const { indexedPaths } = require("../seo.ts");

const domainName = "qrcreator.com";
const theme = "mojito";

import { fonts } from "@/themes/mojito/fonts/fonts";

export const QRCreator: SiteInfo = {
  domainName: domainName,
  siteName: "QR Creator",
  siteTheme: theme,
  isFreeTrial: false,
  siteEmail: `info@${domainName}`,
  siteWhiteLogo: "/svg/logo-white.svg",
  siteBlackLogo32: "/svg/logo32.svg",
  siteBlackLogo28: "/svg/logo28.svg",
  siteBlackLogoFooter: "/svg/logoFooter.svg",
  favicon: "/images/favicon-default.png",
  indexedPaths: indexedPaths[domainName],
  companies: ["ebdaniTradeSL", "globalOpServicesLLC", "onworldDigitalCorporationSL"],
  fonts: onImportFonts(fonts),
};
