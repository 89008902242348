import { Plus_Jakarta_Sans } from "next/font/google";

export const plusJakartaSans = Plus_Jakarta_Sans({
  display: "swap",
  style: ["normal"],
  subsets: ["latin"],
  variable: "--font-plus-jakarta-sans",
  adjustFontFallback: false,
});

export const fonts = {
  plusJakartaSans,
};
