import React from "react";
import classNames from "classnames";
import { ChipProps } from "./types";
import "./styles.scss";

export function Chip({ label, type, color, className, size, width, mobile, tablet, style, icon }: ChipProps) {
  const classnames = classNames(`Chip`, {
    [`Chip--${type}`]: type,
    "Chip--primary": color === "primary",
    "Chip--success": color === "success",
    "Chip--warning": color === "warning",
    "Chip--danger": color === "danger",
    "Chip--inactive": color === "inactive",
    "Chip--medium": size === "medium",
    "Chip--fit-content": width === "fit-content",
    "Chip--tablet": tablet === true,
    "Chip--mobile": mobile === true,
    [className]: className,
  });

  const renderChipWithIcon = () => {
    if (icon) {
      return <div className="text">
        <div className="text__icon text__icon--left">
          {icon.position === "left" && icon.component}
        </div> 
        <span className="text">
          {label}
        </span>
        <div className="text__icon text__icon--right">
          {icon.position === "right" && icon.component}
        </div> 
      </div>
    } else {
      return <span className="text">
        {label}
      </span>
    }
  }

  return (
    <div className={classnames} data-testid="Chip" style={style}>
      {renderChipWithIcon()}
    </div>
  );
}
