import { Nunito_Sans, Poppins } from "next/font/google";

export const nunitoSans = Nunito_Sans({
  display: "swap",
  style: ["normal", "italic"],
  subsets: ["latin"],
  variable: "--font-nunito-sans",
  adjustFontFallback: false,
});

export const poppins = Poppins({
  display: "swap",
  style: ["normal", "italic"],
  subsets: ["latin"],
  weight: ["700"],
  variable: "--font-poppins",
  adjustFontFallback: false,
});

export const fonts = {
  nunitoSans,
  poppins,
};
