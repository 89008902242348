import { domains } from "@/sites/domains";
import { SiteInfo } from "@/sites/types";
import { find } from "lodash";

export const getSiteUrl = () => {
  return `${process.env.NEXT_PUBLIC_HTTP_PROTOCOL}://${process.env.NEXT_PUBLIC_DOMAIN}`;
};

// Get only the domain name. https://fceb876b1.qrcreator.com/pricing?utm=google  -> qrcreator.com
export const getSiteDomain = () => {
  const siteUrl = getSiteUrl();
  const parsedUrl = new URL(siteUrl);
  let domain = parsedUrl.hostname;

  const domainParts = domain.split(".");
  if (domainParts.length >= 2) {
    // Check if the top-level domain is valid
    const lastPart = domainParts[domainParts.length - 1];
    const tldPattern = /^[a-z]{2,}$/i;

    if (tldPattern.test(lastPart)) {
      // Construct the domain from the last two parts (domain name + top-level domain)
      domain = domainParts[domainParts.length - 2] + "." + lastPart;
    }
  }

  // If it is localhost, get the domain name with the theme equal to NEXT_PUBLIC_THEME
  if (domain === "localhost") {
    const theme = process.env.NEXT_PUBLIC_THEME;
    const targetDomainInfo = find(domains, (domain: SiteInfo) => domain.siteTheme === theme);
    
    domain = targetDomainInfo.domainName;
  }

  return domain;
};
