import { AxiosResponse } from "axios";

import client from "../client";
import { getProfileConfig } from "./getProfileConfig";
import { GetProfileResponse } from "./types";

const getProfile = async ({
  authorization,
}: {
  authorization: string | undefined;
}): Promise<AxiosResponse<GetProfileResponse>> => {
  return await client({ ...getProfileConfig(authorization) });
};

export default { getProfile };
