import React from "react";
import toaster, { ToastOptions } from "react-hot-toast";

import { LayoutContext } from "@/contexts/LayoutContext";

import { Position, Toast } from "./types";

export const useToast = () => {
  const layoutContext = React.useContext(LayoutContext);

  const genericErrors = layoutContext?.genericErrors || {};

  const errorOptions = (
    position?: Position,
    marginBotton?: number,
    marginTop?: number,
    duration?: number,
    className?: string
  ): ToastOptions => ({
    style: {
      background: "#ff7f7f",
      color: "white",
      marginBottom: marginBotton && `${marginBotton}px`,
      marginTop: marginTop && `${marginTop}px`,
    },
    iconTheme: {
      primary: "#fff",
      secondary: "#b30000",
    },
    position,
    duration,
    className,
  });

  const successOptions = (
    position?: Position,
    marginBotton?: number,
    marginTop?: number,
    duration?: number,
    className?: string
  ): ToastOptions => ({
    style: {
      background: "#27bfb1",
      color: "white",
      fontWeight: "bold",
      marginBottom: marginBotton && `${marginBotton}px`,
      marginTop: marginTop && `${marginTop}px`,
    },
    iconTheme: {
      primary: "#fff",
      secondary: "#156a62",
    },
    position,
    duration,
    className,
  });

  const notificationOptions = (
    position?: Position,
    marginBotton?: number,
    marginTop?: number,
    duration?: number,
    className?: string
  ): ToastOptions => ({
    style: {
      background: "#4799eb",
      color: "white",
      marginBottom: marginBotton && `${marginBotton}px`,
      marginTop: marginTop && `${marginTop}px`,
    },
    iconTheme: {
      primary: "#fff",
      secondary: "#0f4d8a",
    },
    position,
    duration,
    className,
  });

  const customOptions = (
    position?: Position,
    marginBotton?: number,
    marginTop?: number,
    duration?: number,
    className?: string
  ): ToastOptions => ({
    style: {
      marginBottom: marginBotton && `${marginBotton}px`,
      marginTop: marginTop && `${marginTop}px`,
    },
    position,
    duration,
    className,
  });

  const toast = (props: Toast) => {
    const {
      type,
      marginBottom,
      marginTop,
      duration = 3000,
      position = Position.bottom,
      className,
    } = props;

    if (type === "notification") {
      toaster(
        genericErrors[props.message] || props.message,
        notificationOptions(position, marginBottom, marginTop, duration, className)
      );
    } else if (type === "success") {
      toaster.success(
        genericErrors[props.message] || props.message,
        successOptions(position, marginBottom, marginTop, duration, className)
      );
    } else if (type === "error") {
      toaster.error(
        genericErrors[props.message] || props.message,
        errorOptions(position, marginBottom, marginTop, duration, className)
      );
    } else {
      toaster.custom(
        props.content,
        customOptions(position, marginBottom, marginTop, duration, className)
      );
    }
  };

  return {
    toast,
  };
};
